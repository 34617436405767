<template>
    <ElementLayout>
        <router-view />
    </ElementLayout>
</template>

<script>
import ElementLayout from '../elements/Layout.vue';

export default {
    name: 'Index',
    components: {
        ElementLayout
    }
}

</script>
